(function (angular) {
    'use strict';

    angular.module('app').controller('AppointmentController',
        ['$scope', 'vcRecaptchaService', 'MessageService',
            function ($scope, vcRecaptchaService, MessageService) {
                this.appointment = {};
                $scope.response = null;
                $scope.widgetId = null;
                $scope.loading = false;

                $scope.setResponse = function (response) {
                    $scope.response = response;
                };
                $scope.setWidgetId = function (widgetId) {
                    $scope.widgetId = widgetId;
                };
                $scope.cbExpiration = function () {
                    vcRecaptchaService.reload($scope.widgetId);
                    $scope.response = null;
                };

                $scope.dateOptions = {
                    dateDisabled: disabled,
                    formatYear: 'yy',
                    startingDay: 1,
                    maxDate: new Date(2020, 5, 22),
                    minDate: new Date()
                };

                // Disable weekend selection
                function disabled(data) {
                    var date = data.date,
                        mode = data.mode;
                    return mode === 'day' && (date.getDay() === 0);
                }

                $scope.open = function ($event) {
                    $scope.status.opened = true;
                };

                $scope.setDate = function (year, month, day) {
                    $scope.date = new Date(year, month, day);
                };

                $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
                $scope.format = $scope.formats[0];

                $scope.status = {
                    opened: false
                };

                this.requestAppointment = function (appointment) {
                    $scope.loading = true;
                    var promise = MessageService.requestApointment(appointment);

                    promise.then(
                        function (dataResponse) {
                            alert(dataResponse.message);
                            window.location = '/';
                        },
                        function (dataResponse) {
                            $scope.loading = false;
                            alert('Failed: ' + dataResponse.message);
                            vcRecaptchaService.reload($scope.widgetId);
                            $scope.response = null;
                        }
                    );

                };
            }]);

    angular.module('app').controller('CarouselDemoCtrl',
        ['$scope', function ($scope) {
            $scope.myInterval = 5000;
            $scope.noWrapSlides = false;
            $scope.active = 0;

            $scope.slides = [
                {
                    image: '/imgs/slides/banner1.jpg',
                    text: null,
                    id: 0
                },
                {
                    image: '/imgs/slides/banner2.jpg',
                    text: null,
                    id: 1
                },
                {
                    image: '/imgs/slides/banner3.jpg',
                    text: null,
                    id: 2
                }
            ];
        }]);

    angular.module('app').controller('GalleryCtrl', ['$scope', 'Lightbox', 'ImageService',
        function ($scope, Lightbox, ImageService) {
        $scope.images = [];

        var qPromise = ImageService.loadOfficeGallery();

        qPromise.then(function (images) {
            $scope.images = images;
        }, function (data) {
            console.log(data);
        });

        $scope.openLightboxModal = function (index) {
            Lightbox.openModal($scope.images, index);
        };
    }]);

})(window.angular);